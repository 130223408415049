@keyframes fadeOutScale {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: scale(1.3);
	}
}

@keyframes focusZoom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes focusFade {
	0% {
		color: #424242;
	}
	50% {
		color: #878787;
	}
	100% {
		color: #424242;
	}
}

.app-panel-select-shirt {

	.example-enter {
		opacity: 0.01;
		.shirt-card-outer {
			transform: scale(0);
		}
	}

	.example-enter.example-enter-active {
		opacity: 1;
		transition: all 500ms ease-in;
		.shirt-card-outer {
			transform: scale(1);
		}
	}

	.example-leave {
		opacity: 1;
	}

	.example-leave.example-leave-active {
		opacity: 0.01;
		transition: all 300ms ease-in;
	}

	&.transitioning {
		animation-delay: 2s;
		// animation: fadeOut 1s ease forwards;
		/* ul.shirt-grid-list li:not('.selected') {
			animation: fadeOutScale 1s ease forwards;
		} */
	}

	
	
	/* shirt grid */
	.shirt-grid-outer {
		ul.shirt-grid-list {
			position: relative;
			height: 700px;
		}
	}


}






@media(max-width: 1100px) {
	
	/* shirt grid */
	.app-panel-select-shirt {
		
		.shirt-grid-outer {
			ul.shirt-grid-list {
				li {
					width: 25%;
				}
			}
		}
	}

}


@media(max-width: 880px) {
	
	/* shirt grid */
	.app-panel-select-shirt {

		.shirt-grid-outer {
			ul.shirt-grid-list {
				li {
					width: 33.3333%;
				}
			}
		}
	}

}









