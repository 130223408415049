.main-header {
	z-index: 100;
	position: fixed;
	width: 100%;
	height: 60px;
	color: #282830;
	background-color: #282830;
	&__inner {
		display: flex;
	}
	&__column {
		width: 33.3333%;
		flex-basis: 33.3333%;
		&--right {
			text-align: right;
		}
	}
	&__pricing {
		display: flex;
		// background: red;
		height: 100%;
		align-items: center;
		justify-content: flex-end;
		padding-right: 30px;
		color: #fff;
	}
	h1 {
		font-weight: 300;
		font-size: 1.35em;
		padding: 0 0 0 10px;
		margin: 0;
		line-height: 60px;
		height: auto !important;
		color: #fff;
		text-align: center;
	}
	.app-icon-outer {
		padding: 18px 13px 13px 7px;
		opacity: 0.4;
		transition: opacity 0.2s ease;
		path {
			fill: #fff;
		}
		&:hover {
			opacity: 0.8;
		}
	}
	.header-logo-outer {
		img {
			height: 41px;
		}
	}
	.page-title-outer {
		width: calc(100% - 135px);
		h2 {
			color: #272727;
			font-size: 1.4em;
			text-align: center;
			padding: 17px 0;
			margin: 0;
			text-align: center;
		}
	}
	a.header-login {
		&:link, &:visited {
			color: #fff;
			opacity: 0.7;
			transition: opacity 0.2s ease;
			display: inline-block;
			margin: 5px 11px;
		}
		&:hover, &:active {
			opacity: 1;
		}
	}
}





@media(max-width: 600px) {
	
	.main-header {
		.header-logo-outer {
			display: none;
		}
		.page-title-outer {
			width: 100%;
		}
	}

}

















