.card-list-outer {
	ul, .card-list {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		li {
			width: 25%;
			margin-bottom: 30px;
			.card-inner {
				position: relative;
				padding: 0;
				margin: 5px;
				background-color: #fff;
				box-shadow: 2px 2px 1px rgba(97, 97, 97, 0.16);
				border-radius: 4px;
				border: solid thin #f1f1f1;
				overflow: hidden;
				transition: all 0.2s ease;
			}
			.admin-card-footer {
				padding: 3px 16px 7px;
				position: absolute;
				width: 100%;
				background: #fff;
				transition: all 0.2s ease;
				transform: translateY(-30px);
				height: auto;
			}
			&:hover {
				.admin-card-footer {
					transform: translateY(-100%);
					transition: all 0.1s ease;
				}
				.card-inner {
					box-shadow: 1px 3px 2px 1px rgba(97, 97, 97, 0.21);
				}
			}
			.card-title {
				display: flex;
				justify-content: space-between;
				font-family: 'Oswald', sans-serif;
			}
			.title-tag {
				margin: 5px 0;
			}
			h4 {
				font-size: 15px;
				color: $text-black;
				padding: 0;
			}
			p {
				padding: 0;
				margin: 0;
				colour: #8B8B8B;
				&.base-price {

				}
				&.colour-count {

				}
			}
			a {
				color: #5F5F5F;
				&:hover, &:active {
					text-decoration: none;
				}
				&:focus {
					outline: none;
				}
			}
			img {

			}
			.image-radio {
				display: none;
				&:checked + .image-selected-border {
					border-width: 10px;
				}
			}
			.image-label {
				background-color: transparent;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				text-indent: -100000px;
				overflow: hidden;
				cursor: pointer;
				z-index: 10;
			}
			.image-selected-border {
				background-color: transparent;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 5;
				border: solid 0 $blue;
				transition: border-width 0.1s ease-in-out;
			}
		}
	}
}
