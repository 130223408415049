.shirt-card {
	margin-bottom: 0;
	position: absolute;
	animation-delay: 0.4s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in;
	transform-origin: top left;
	&__link {
		&:hover, &:active {
			.shirt-card__inner {
				display: block;
				// box-shadow: 0px 3px 15px -1px rgba(0, 0, 0, 0.35);
			}
			.shirt-card__footer {
				display: block;
				// transform: translateY(10px);
			}
			.shirt-card__overlay {
				// background-color: rgba(51, 50, 53, 0.45);
			}
			.shirt-card__add-to-quote {
				transform: translateY(-7px);
				opacity: 1;
			}
			.shirt-card__bg {
				transform: scale(1.05);
				opacity: 0.55;
			}
		}
		&:focus {
			.shirt-card__inner {
				animation: focusZoom 0.15s ease-in-out;
				// box-shadow: 0px 3px 15px -1px rgba(0, 0, 0, 0.35);
			}
			.shirt-card__overlay {
				background-color: rgba(51, 50, 53, 0.45);
				opacity: 0;
			}
			.shirt-card__footer {
				// transform: translateY(4px);
			}
			.shirt-card__bg {
				opacity: 1;
			}
			.shirt-card__footer {
				opacity: 0;
			}
		}
	}
	&--selected {
		display: none;
		.shirt-card__inner {
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			box-shadow: none;
		}
		.shirt-card__overlay {
			// background-color: rgba(51, 50, 53, 0);
			opacity: 0 !important;
		}
		.shirt-card__footer {
			opacity: 0 !important;
		}
		.shirt-card__bg {
			opacity: 1;
		}
	}
	&__bg {
		transition: all 0.4s ease;
		position: absolute;
		width: 100%;
		height: calc(100% - 60px);
		background-size: cover;
		background-position: top center;
		top: 0;
		left: 0;
	}
	&__inner {
		height: 100%;
		left: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 0;
		transition: 
		box-shadow 0.2s ease, 
		top 0.2s 0.4s ease, 
		left 0.2s 0.4s ease, 
		height 0.2s 0.4s ease, 
		width 0.2s 0.4s ease;
		width: 100%;
	}
	&__add-to-quote {
		border: 2px solid;
		color: $text-black;
		display: inline-block;
		font-size: .85em;
		font-weight: 700;
		margin-top: -35px;
		opacity: 0;
		padding: 3px 0;
		text-align: center;
		text-transform: uppercase;
		transition: all 0.2s ease;
		width: 111px;
		transform: translateY(-20px);
		&:hover {
			background-color: #fff;
			border-color: #fff;
			color: $text-primary;
		}
	}
	&__overlay {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: rgba(51, 50, 53, 0);
		transition: all 0.3s ease;
		opacity: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;	
	}
	&__footer {
		z-index: 20;
		position: absolute;
		width: 100%;
		background: #fff;
		transition: opacity 0.4s ease;
		bottom: 0;
		height: 50px;
		width: 100%;
		left: 0;
		text-align: center;
	}
	&__footer-inner {
		position: absolute;
		color: inherit;
		height: 45px;
		width: 100%;
		top: 0;
		left: 0;
		padding: 0 9px;
		transition: all 0.2s ease;
	}
	&__info {
		display: block;
		display: none;
	}
	&__price-colours {
		display: flex;
		justify-content: space-between;
		text-align: left;
		margin-top: 7px;
	}
	&__title {
		padding: 0;
		margin: 6px 0 0 0;
		font-weight: 600;
		text-align: center;
	}
	&__pricing,
	&__colours {
		color: #888888!important;
		font-weight: 400;
		font-size: 0.92em !important;
	}
	&__pricing {

	}
	&__price {
		color: $text-black;
		font-weight: 600;
	}
	p {
		font-family: 'Roboto', sans-serif;
		color: #969696;
		font-size: 0.9em;
	}
	&__brand {
		text-align: center;
		text-transform: uppercase !important;
		font-size: 0.8em !important;
		margin: 0 !important;
	}

}



.shirt-card--selected .shirt-card__bg {
	// height: 100%;
	// transform: scale(0.5);
}