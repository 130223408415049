/* addons */
.app-addons {
	    overflow: hidden;
    margin-top: 80px;
    margin-bottom: 100px;
	&__inner {
		margin-top: 40px;
	}
	&__list {
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		max-width: 550px;
		margin: 0 auto;
	}	
	&__item {
		position: relative;
		text-align: center;
		width: 33.3333%;
		display: inline-block;
		padding: 0 2px;
		margin: 0 auto;
		&--active {
			.app-addons__price, .app-addons__title {
				color: $text-primary;
			}
			.addon-icon-bg {
				height: calc(100% - 11px);
				width: calc(100% - 11px);
			}
		}
	}
	&__link {
		&:link, &:visited {
			padding: 10px;
			display: inline-block;
			color: $text-light;
			font-weight: 500;
			display: block;
			border-radius: 4px;
			transition: all 0.3s ease;
		}
		&:focus {
			.addon-icon-outer {
				animation: focusZoom 0.2s ease forwards;
				&:after {
					animation: afterZoom 0.8s ease forwards;
				}
			}
		}
		&:hover, &:active {
			text-decoration: none;
			.app-addons__price, .app-addons__title {
				color: $text-primary;
			}
		}	
	}
	&__title {
		margin-top: 12px;
		color: inherit;
		font-size: 1.09em;
		transition: color 0.2s ease;
	}
	&__price {
		font-size: 0.9em;
		margin: 3px 0 0px;
		color: #919191;
		transition: color 0.2s ease;
	}
	&__icon-outer {
		transition: all 0.3s ease;
		padding: 0;
		width: 30px;
		height: 30px;
		position: relative;
		margin: 0 auto;
		&:after {
			content: "";
			position: absolute;
			border-radius: 50%;
			transition: all 0.2s ease;
			display: inline-block;
			height: 0;
			width: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: rgba(101, 131, 241, 1);
		}
	}
	&__icon {
		display: inline-block;
		height: 30px;
		width: 30px;
		top: 4px;
		font-size: 40px;
		border: solid 2px #666666;
		border-radius: 50%;
		position: relative;
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
	}
	&__icon-bg {
		position: absolute;
		content: "";
		display: block;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: transparent;
		border-radius: 50%;
		z-index: 5;
		transition: all 0.3s ease;
		background: #f16565;
		height: 0;
		width: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
		
}
