/**
 * Side bar & Main Navigation
 */
 .sidebar {
 	width: $sidebar_width;
 	position: fixed;
 	height: 100%;
 	top: 0;
 	border-right: rgba(255, 255, 255, 0.2);
 	z-index: 1000;
 	&--shirts {
 		.link-to-customize, .link-to-submit {
 			a {
 				pointer-events: none;
 				cursor: default;
 			}
 		}

 	}
 	&--customize {
 		.navigation__item {
 			&--shirts {
 				&:before {
 					height: calc(100% - 50px);
 				}
 				.navigation__link {
 					border: solid 3px $app-purple;
 					&:hover {
 						background-color: rgba(106, 27, 154, 0.1);
 					}
 				}
 				path {
 					fill: $app-purple;
 				}
 			}
 		}
 	}
 	&--submit {
 		.navigation__item {
 			&--shirts, &--customize {
 				&:before {
 					height: calc(100% - 50px);
 				}
 				.navigation__link {
 					border: solid 3px $app-purple;
 					&:hover {
 						background-color: rgba(106, 27, 154, 0.1);
 					}
 				}
 				path {
 					fill: $app-purple;
 				}
 			}
 		}
 	}
 	&__panel-inner {
 		position: relative;
 	}
 	&__active-nav-bg {
 		position: absolute;
 		top: 0;
 		left: 0;
 		width: 100%;
 		height: calc(33.3333% / 2 - 25px);
 		transition: transform 0.5s cubic-bezier(0.4, 0, 1, 1);
 		border-right: solid 4px $app-green;
 		z-index: -1;
 		display: none;
 	}
 	&__progress-border {
 		content: "";
 		position: absolute;
 		height: 100%;
 		width: 4px;
 		right: 0;
 		background: rgba(255, 255, 255, 0.42);
 		z-index: -1;
 		display: none;
 		span {
 			background: #BF55EC;
 			display: block;
 			position: absolute;
 			height: 0;
 			width: 100%;
 			transition: height 0.5s cubic-bezier(0.4, 0, 1, 1);
 		}
 	}
 }

 .navigation {
 	.sidebar-header {
 		height: 55px;
 		background-color: #2980b9;
 		h1.logo-text {
 			color: $light-white;
 			margin: 0;
 			padding: 20px 19px;
 			font-size: 1.3em;
 			font-weight: 100;
 			a {
 				color: inherit;
 				&:hover, &:active {
 					text-decoration: none;
 					color: inherit;
 					font-weight: normal;
 				}
 			}
 		}
 	}
 }	


 .navigation {

 	&__list {
 		display: flex;
 		flex-direction: column;
 		justify-content: space-between;
 		height: 75vh;
 		list-style-type: none;
 		z-index: 5;
 		margin: 0;
 		padding: 0;
 		margin-top: 60px;
 	}
 	&__item {
 		margin: 0;
 		flex-grow: 1;
 		display: flex;
 		align-items: center;
 		position: relative;
 		&:after, &:before {
 			position: absolute;
 			content: "";
 			display: block;
 			width: 2px;
 			height: calc(100% - 50px);
 			top: calc(50% + 25px);
 			left: 50%;
 			background-color: $text-light;
 			z-index: 3;
 		}
 		&:before {
 			width: 3px;
 			background-color: $app-purple;
 			z-index: 6;
 			transform: translateX(-50%);
 			height: 0;
 		}
 		&:last-child {
 			&:after, &:before {
 				display: none;
 			}
 		}
 		&--shirts {
 			.app-icon-outer {
 				height: 26px;
 			}
 		}
 		&--customize {
 			.app-icon-outer {
 				height: 20px;
 			}
 		}
 		&--submit {
 			.app-icon-outer {
 				height: 25px;
 			}
 		}
 	}
 	&__link {
 		&:link, &:visited {
 			color: rgba(255, 255, 255, 0.26);
 			transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
			display: block;
			font-weight: 300;
			text-transform: uppercase;
			font-size: 0.85em;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			border-radius: 50%;
			border: solid 2px $text-light;
			width: 50px;
			height: 50px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		&:hover, &:focus, &:active {
			color: $light-white;
			.nav-icon path {
				// fill: $light-white;
			}
		}
		&--active {
			&:link, &:visited {
				background-color: $app-purple;
				border-color: $app-purple;
				.nav-icon path {
					fill: #fff;
				}
			}	
		}
	}
	&__label {
		position: relative;
		display: block;
		padding-top: 6px;
		display: none;
	}
	path {
		fill: $text-light;
		transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
	}
}

.app-container.shirt-selected {
	.app-column-sidebar.colours {
		.link-to-submit {
			a {
				pointer-events: all;
				cursor: pointer;
			}
		}
	}
}


@media(max-width: 600px) {
	.app-container {
		.sidebar {
			display: none;
		}
		.main-panel-outer {
			width: 100%;
			transform: none;
		}
	}
}










