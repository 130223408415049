.panel-header {
	margin: 0 0 40px 0;
	&__inner {

	}
	/* h2 */
	&__title {
		font-size: 2.4em;
		margin: 10px 0 6px;
		text-align: left;
		color: #313131;
		font-weight: 400;
		line-height: 1;
	}
	&--customize {
		margin-bottom: 0;
	}
}