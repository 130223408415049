/**
 * Colour Grid
 */
 @keyframes fadeOutScale {
 	0% {
 		opacity: 1;
 	}

 	100% {
 		opacity: 0;
 		transform: scale(1.3);
 	}
 }

 @keyframes fadeInSlideUp {
 	0% {
 		opacity: 0;
 		transform: translateY(40px);
 		height: 0;
 	}

 	30% {
 		height: 82px;
 		opacity: 0;
 		transform: translateY(40px);
 	}

 	100% {
 		opacity: 1;
 		transform: translateY(0);
 	}
 }

 @keyframes fadeOutSlideUp {
 	0% {
 		opacity: 1;
 		transform: translateY(0);
 	}

 	30% {
 		height: 82px;
 		opacity: 0;
 		transform: translateY(40px);
 	}

 	100% {
 		opacity: 0;
 		transform: translateY(40px);
 		height: 0;
 	}
 }

 @keyframes focusZoom {
 	0% {
 		transform: scale(1);
 	}
 	50% {
 		transform: scale(0.95);
 	}
 	100% {
 		transform: scale(1);
 	}
 }

 .colour-grid-outer {
 	&.transitioning {
 		animation-delay: 1.2s;
 		animation: fadeOut 0.5s ease forwards;
 		ul.colour-grid-list li {
 			animation: fadeOutScale 0.2s ease forwards;
 		}
 	}
 }


.colour-swatch-list {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
}
.colour-swatch {
	width: 105px;
	margin-bottom: 0;
	&__inner {
		background: transparent;
		border-radius: 0;
		border: none;
		border: solid 2px transparent;
		height: 105px;
		height: 93px;
		margin: 5px 5px 17px 5px;
		overflow: visible;
		padding: 0;
		position: relative;
		position: relative;
		transition: all 0.2s ease;
	}
	&__thumbnail-outer {
		border: none;
		display: block;
		height: 100%;
		left: -2px;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: -2px;
		top: 0;
		transition: all 0.2s;
		width: 100%;
		width: calc(100% + 4px);
		z-index: -1;
	}
	&--active {
		.colour-swatch__inner {
			box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.25);
			border-color: $app-purple;
		}
		.colour-swatch__footer {
			background-color: $app-purple;
		}
		.colour-swatch__title {
			color: #fff;
		}
	}
	&__link {
		&:hover, &:active, &:focus {
			.colour-swatch__inner {
				box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.25);
			}
		}
		&:focus {
			.colour-swatch__inner {
				animation: focusZoom 0.2s ease-in-out;
			}
		}
	}	
	&__footer {
		background: rgba(255, 255, 255, 0.73);
		bottom: -2px;
		display: block;
		height: auto;
		left: -2px;
		padding: 0;
		padding: 3px 9px 2px;
		position: absolute;
		top: auto;
		transform: translateY(0) !important;
		width: calc(100% + 4px);
	}
	&__title {
		color: $text-black;
		font-family: Oswald;
		font-size: 13px;
		line-height: 1;
		margin: 0;
	}
}
















