.colour-qty-form {
	&__form-row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	&__input-column {
		position: relative;
		width: 38px;
		margin-bottom: 14px;
		text-align: center;
	}
	&__input-outer {
		position: relative;
		display: flex;
		flex-direction: column;
		&:before {
			position: absolute;
			height: 2px;
			bottom: 0;
			background-color: $app-purple;
			transition: all 0.2s ease;
			left: 0;
			right: 100%;
			content: "";
			display: block;
		}
		&.active {
			&:before {
				right: 0;
			}
			label {
				color: $app-purple;
			}
		}
	}
	&__input {
		&--number {
			width: 33px;
			color: #252525;
			line-height: 1;
			padding: 8px 0 2px;
			text-align: left;
			color: #000000;
			border-color: #757575;
			font-weight: 400;
			margin: 0 auto;
			text-align: center;
			order: 2;
			transition: color 0.2s ease-in-out;
			&:focus {
				border-color: $purple;
				+ label {
					color: $purple;
				}
			}
		}
	}
	&__label {
		position: relative;
		padding-right: 6px;
		margin: 0;
		line-height: 1.2;
		padding: 0;
		color: #757575;
		font-size: 0.9em;
		font-weight: 500;
	}
}

.colour-qty-form-list,
.colour-qty-form-outer {
	&.transition-out {
		animation: fadeOutSlideUp 0.5s ease forwards;
	}
}
.colour-qty-form-inner {
	position: relative;
	display: flex;
	min-height: 85px;
}
.colour-form-swatch {
	.colour-square {
		display: block;
		height: 82px;
		width: 108px;
		margin-top: -1px;
		margin-bottom: -1px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		h5 {
			display: none;
		}
	}
}

