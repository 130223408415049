.flexbox {
	display: flex;
	.col-2 {
		width: 16.66666667%;
	}
	.col-3 {
		width: 25%;
	}
	.col-4 {
		width: 33.33333333%;
	}
	.col-6 {
		width: 50%;
	}
}