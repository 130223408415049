/**
* General
*/
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}
html {
	font-size: 100%;
}
body {
	font-family: 'Open Sans', sans-serif;
	background-color: #fff;
	font-size: 14px;
	line-height: 1.42857;
}
img {
	max-width: 100%;
}
h1, .h1, h2, .h2, h3, .h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}
h1 {
	font-size: 36px;
}
h2 {
	font-size: 30px;
}

a {
	text-decoration: none;
}
p {
	margin: 0px 0px 10px;
}

.app-wrapper-outer {
	width: 100%;
}
.app-container-outer {
	display: flex;
	justify-content: space-between;
}
.app-container {
	display: flex;
	justify-content: space-between;
	.panel-inner {
	}
	.main-panel-outer {
		.main-panel-inner {
			padding: 30px 20px 10px;
		}
	}
	.preview-pane {
		color: inherit;
	}
	&.shirt-selected {
		.main-panel-inner {
			width: calc(100% - 500px);
		}
	}
}



/**
* Buttons
*/
.app-button {
	&:link, &:visited {
		font-size: 14px;
		display: block;
		background-color: #1BBC9B;
		border: solid thin #128e75;
		color: #fff;
		padding: 9px 21px 7px;
		text-align: center;
		text-transform: uppercase;
		border-radius: 0;
		font-size: 13px;
		font-weight: 400;
		transition: all 0.2s ease;
		border: none;
		font-weight: 300;
		position: relative;
	}
	&:hover, &:active {
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.3);
		opacity: 0.85;
	}
	&.primary {
		background-color: #1565C0;
	}
	&.purple {
		background-color: $app-purple;
	}
	&.app-button-icon {
		padding: 9px 21px 7px 37px;
		span.lnr {
			position: absolute;
			left: 9px;
			top: 8px;
			font-size: 1.35em;
			opacity: 0.5;
		}
	}
}
.next-panel {
	overflow: hidden;
	margin: 50px 20px;
	.next-panel-inner {
		max-width: 450px;
		margin: 0 auto;
		padding: 8px 0;
	}
	/* a {
		&:link, &:visited {
			font-size: 1.1em;
			display: block;
			padding: 9px 0 7px 0;
			text-align: center;
			text-transform: uppercase;
			transition: 0.2s ease;
			background-color: $flat-purple;
			color: #fff;
		}
		&:hover, &:active {
			opacity: 1;
			background-color: lighten($flat-purple, 8%);
		}
	} */
}



/**
 * Common
 */
 .section-label {
 	color: $light-label-color;
 	font-weight: 500;
 	font-size: 14px;
 	text-transform: uppercase;
 }
 .app-help-text {
 	display: block;
 	p {
 		font-weight: 100;
 	}
 }









