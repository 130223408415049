@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}



	/* 
	shouldnt need anymore

	position: relative;
	width: 100%;
	z-index: 20;
	overflow: hidden;
	margin: 16px auto;
	width: 100%; */
	/* max-width: 350px; */
	.shirt-description {
		text-align: center;
		max-width: 500px;
		margin: 20px auto 20px;
		position: relative;
		overflow: hidden;
		padding: 15px 0;
		&:before, &:after {
			width: 60px;
			height: 1px;
			background-color: $text-light;
			left: 50%;
			top: 0px;
			content: "";
			display: block;
			position: absolute;
			transform: translateX(-50%);
		}
		&:after {
			bottom: 0;
			top: auto;
		}
	}

	.preview-dropzone-outer {
		animation: fadeIn 0.8s ease forwards;
		opacity: 0;
		animation-fill-mode: forwards;
		animation-delay: 0.3s;
	}
	.preview-pane {
		overflow: scroll;
		height: calc(100% );
		background-color: #fff;
		.preview-image {
			height: calc(100vh - 60px);
			width: 100%;
			position: relative;
		}
		.preview-image-inner {
			position: absolute;
			height: 100%;
			top: 0;
			width: 100%;
			background-size: cover;
			background-position: top center;
		}
		.front-back-nav {
			position: absolute;
			bottom: 40px;
			right: 15px;
			animation: fadeIn 0.8s ease forwards;
			opacity: 0;
			animation-fill-mode: forwards;
			animation-delay: 3s;
			.thumbnail-link{
				transition: all 0.2s ease;
			}
			&.active-front {
				.activate-front {
					&:link, &:visited {
						border: solid 3px $app-secondary;
					}
				}
			}
			&.active-back {
				.activate-back {
					&:link, &:visited {
						border: solid 3px $app-secondary;
					}
				}
			}
			ul {
				list-style-type: none;
			}
			li {
				width: 55px;
				margin-bottom: 5px;
				.thumbnail-link{
					&:link, &:visited {
						border: solid thin #E1E1E1;
						display: block;
					}
					&:hover, &:active {

					}
				}
				img {
					display: block;
				}
			}
		}
		.panel-inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.preview-cart {
			display: none;
			position: fixed;
			width: 380px;
			bottom: 0;
			z-index: 10;
			padding: 10px 0;
			background-color: rgba(255, 255, 255, 0.85);
			h4 {
				padding: 0 15px 4px 15px;
			}
		}
		.preview-colour-information {
			padding: 0;
			li.preview-cart-item {
				font-size: 1.05em;
				border-bottom: solid thin $light-border-color;
				padding: 8px 15px 5px 41px;
				line-height: 1;
				transition: all 0.2s ease;
				position: relative;
				&:hover {
					background-color: rgba(255, 255, 255, 0.58);
				}
				.preview-cart-item-swatch {
					position: absolute;
					height: 15px;
					width: 15px;
					border-radius: 3px;
					top: 15px;
					left: 15px;
					display: block;
				}
				.preview-cart-item-title {
					display: flex;
					justify-content: space-between;
					h5 {
						margin: 0;
						font-weight: bold;
						color: #353535;
					}
					.colour-total-price {
						font-weight: 500;
					}
				}
				.preview-cart-item-info {
					display: block;
					font-size: 0.85em;
					padding: 7px 0 0px 0;
					font-weight: 500;
					color: #8e8e8e;
				}
				ul.preview-cart-info-list {
					list-style-type: none;
					li {
						display: inline;
						padding-right: 15px;
					}
					.preview-cart-item-value {
						color: #404040;
						font-weight: 600;
					}
				}
			}
		}
		.section-artwork-addons {
			position: absolute;
			width: 100%;
			max-width: 170px;
			height: 140px;
			transform: translate(-50%, 75px);
			left: 50%;
			.artwork-columns {
				.thumbnail-link {
					transition: all 0.2s ease;
				}
				&.active-front {
					.artwork-column.front {
						display: block;
					}
					.thumbnail-link {
						border: solid 3px $app-secondary;
					}
				}
				&.active-back {
					.artwork-column.back {
						display: block;
					}
					.thumbnail-link{
						border: solid 3px $app-secondary;
					}
				}
			}
			.artwork-column {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				display: none;
				h3 {
					display: none;
				}
				.upload-drop {
					width: 100%;
				}
				.dropzone {
					display: none;
				}
				.upload-icon {
					color: #757575;
				}
			}
		}
	}
	.artwork-upload-drop {
		box-shadow: none;
		position: absolute;
		height: 250px;
		width: 100%;
		transition: all 0.2s ease;
		cursor: pointer;
		display: flex;
		align-items: center;
		border: solid 6px hsla(0, 0%, 0%, 0.13);
		border-radius: 0;
		background-color: hsla(0, 0%, 0%, 0.2);
		&:hover {
			background-color: hsla(0, 0%, 0%, 0.3);
			border: solid 6px hsla(0, 0%, 0%, 0.2);
		}
		img {
			position: absolute;
			object-fit: cover;
			object-position: center;
			height: 100%;
			width: 100%;
			max-width: none;
		}
		p {
			font-size: 1em;
			font-weight: 500;
			text-align: center;
		}
		.instructions {
			color: #fff;
			cursor: pointer;
			display: flex;
			align-items: center;
		}
		.file-types {
			color: #fff;
		}
		
		.dropzone-area-info {
		}
		&--has-thumb,
		&--uploading {
			background: transparent;
			border: none;
			&:hover {
				background: transparent;
				border: none;
			}
		}
	}
	.dropzone-area-info {
		padding: 2px 15px;
		text-align: center;
		color: #232323;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.28);
		opacity: 0.6;
		position: absolute;
		top: 0;
		color: #fff;
		padding: 15px;
		font-weight: 100;
		text-align: center;
	}
	.image-info {
		&__text {
			position: relative;
			z-index: 10;
			height: 250px;
			padding: 4px 10px;
		}
		&__btn {
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			text-align: center;
			margin: 0;
			padding: 0;
			line-height: 1.5;
			display: block;
			cursor: pointer;
			z-index: 10;
			top: 5px;
			right: 5px;
		}
		&__title {
			color: #fff;
			font-size: 0.85em;
			line-height: 1.5;
			padding: 2px 3px;
			font-weight: 300;
			width: 100%;
		}
		&__thumb {

			position: absolute;
			z-index: 10;
		}
	}


	@media(max-width: 991px) {
		.app-container .preview-pane {
			transform: translateX(100%) !important;
		}
		.app-container.colours .main-panel-inner, .app-container.artwork .main-panel-inner, .app-container.submit .main-panel-inner {
			width: 100% !important;
		}
	}






