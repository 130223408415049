@keyframes fadeOutScale {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: scale(1.1);
	}
}


.panel-section-submit {
	&.transitioning {
		animation-delay: 0.3s;
		animation: fadeOut 0.5s ease forwards;
		ul.quote-cart .cart-item {
			animation: fadeOutScale 0.5s ease forwards;
		}
	}

	.red {
		color: red;
	}
	.order-notes {
		background-color: #fff;
		border: solid thin #eee;
		box-shadow: 0px 2px 4px 1px rgba(0,0,0,.16);
		margin-bottom: 15px;
		padding: 50px 30px 0;
	}
	.order-note {
		margin-bottom: 80px;
	}
	.order-note-title {
		width: calc(50% - 20px);
		margin: 20px auto 50px;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		h2, h3, h4, h5, span {
			line-height: 1;
			margin: 0;
		}
		&.quote-cart {
			.cart-item {
				background-color: #fff;
				border: solid thin $light-border-color;
				box-shadow: -1px 1px 18px 2px rgba(0, 0, 0, 0.26);
				margin-bottom: 15px;
				padding: 0;
				color: $text-black;
				// border-radius: 5px;
				position: relative;
			}
			ul.cart-item-list {
				margin-top: 12px;
			}
		}
		.cart-shirt-header {
			display: flex;
			justify-content: space-between;
			padding: 14px 10px 11px;
			background-color: $app-primary;
			border: inherit;
			color: #fff;
			margin: -1px 0 0 -1px;
			width: calc(100% + 2px);
			// border-top-right-radius: 5px;
			// border-top-left-radius: 5px;
			border: solid thin $app-primary;
			span {
				color: #555555;
			}
			ul {
				display: inherit;
			}
			h4 {
				font-size: 1.5em;
				text-align: center;
				font-weight: 100;
				font-size: 1.7em;
			}
			p {
				margin: 6px 0 0 0;
			}
			.cart-colour-title {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			.cart-colour-price {
				font-weight: bold;
			}
			.cart-colour-quantity {
				padding-left: 20px;
				font-style: italic;
				font-size: 1em;
			}
			ul.cart-shirt-actions {
				margin: 0 7px 0 0;
				li {
					display: inline-block;
					padding-left: 10px;
					a {
						&:link, &:visited {
							color: #fff;
							opacity: 0.7;
							transition: opacity 0.2s ease;
						}
						&:hover, &:active {
							opacity: 1;

						}
					}
				}
			}
		}
		.cart-list-outer {
			margin: 15px 0 0 0;
			&.cart-list-outer-addons {
				margin-top: 30px;
			}
		}
		.cart-shirt-content {
			display: flex;
			.cart-shirt-thumbnail {
				position: relative;
				width: 190px;
				margin-top: 16px;
			}
		}
		.cart-sub-list-item {
			position: relative;
			margin-bottom: 25px;
		}
		.cart-item-colour-swatch {
			height: 15px;
			width: 15px;
			position: absolute;
			display: block;
			top: 2px;
			left: -22px;
			border-radius: 4px;
		}
		.cart-shirt-info {
			padding: 10px 20px 11px 34px;
			width: calc(100% - 190px);
		}
		.shirt-info-main {
			position: relative;
			.cart-colour-title-outer {
				display: flex;
				justify-content: space-between;
				font-weight: bold;
				font-size: 1.1em;
			}
			.cart-sub-list-item-total {
				font-weight: bold;
			}
		}
		.cart-sub-list-item {
			header.cart-sub-list-item-header {
				margin-top: 10px;
				position: relative;
				.cart-colour-info {
					color: #929292;
					font-size: 0.95em;
					padding: 7px 0 3px;
					ul {
						list-style-type: none;
						li {
							display: inline;
							padding-right: 15px;
						}
						.preview-cart-item-value {
							color: #404040;
							font-weight: 600;
							font-size: 1.1em;
						}
					}
				}
			}
		}
		.cart-colour-header {
			
			span {
				color: #555555;
			}
			.cart-colour-title {
				
			}
			.cart-colour-price {
			}
			.cart-colour-quantity {
				padding-left: 20px;
				font-style: italic;
				font-size: 1em;
			}
		}
		.cart-colour-sizes {
			max-width: 500px;
			display: flex;
			flex-wrap: wrap;
			padding: 4px 0 4px;
			li {
				padding-right: 25px;
				font-size: 0.9em;
				span {
					display: inline-block;
				}
				.size-label {
					color: #888888;
					padding-right: 7px;
					font-size: 0.8em;
					font-weight: bold;
				}
				.size-value {
					font-weight: bold;
					font-size: 1.1em;
				}
			}
		}
		.cart-shirt-header-options {

		}
		.cart-list-outer-addons {
			.cart-item-list {
				overflow: hidden;
			}
			li.cart-addon-item {
				display: inline;
				float: left;
				padding: 0px 22px 14px 0;
				h6 {
					color: inherit;
					padding: 0 0 0 28px;
					line-height: 1.8;
				}
			}
			.addon-icon-outer {
				position: absolute;
				color: inherit;
				font-size: 20px;
			}
		}
		.quote-price-totals {
			display: flex;
			justify-content: space-between;
			padding: 0 0 15px 0;
			margin: 25px 0 0 0;
			h4, h5 {
				margin: 0;
			}
			span {
				font-size: 1.2em;
				font-weight: bold;
			}
		}
		.cart-price-totals {
			display: block;
			text-align: center;
			margin-top: 70px;
			h3 {
				font-size: 1.3em;
				font-weight: 100;
			}
			span {
				font-size: 1.6em;
				display: inline-block;
				margin: 15px 0 0 0;
			}
		}
		.quote-price-totals {
			margin-top: 10px;
		}

		a.edit-shirt-quote {
			&:link, &:visited {

			}
			&:hover, &:active {

			}
		}
	}
	.order-note-artwork {
		img {
			max-width: 200px;
		}
		.dropzone {
			display: none;
		}
		.order-note-artwork-inner {
			max-width: 300px;
			margin: 0 auto;
			text-align: center;
		}
		.filepicker.dropzone {
			min-height: 0;
			border: none;
			background: #1565c0;
			color: #fff;
			padding: 12px 0;
			display: block;
			font-weight: 300;
			text-transform: uppercase;
			transition: opacity 0.2s ease;
			&:hover {
				opacity: 0.8;
			}
			.dz-message {
				margin: 0;
			}
			.dz-preview {
				display: none;
			}
		}
	}
	
	.app-form-control {
		&.message-row {
			margin-top: 12px;
		}
		&.submit-row {
			text-align: center;
			margin-top: 30px;
			input[type="submit"] {
				display: inline-block;
				background-color: #8e44ad;
				border: solid thin #823E9F;
				color: #fff;
				padding: 13px 47px 12px;
				text-align: center;
				text-transform: uppercase;
				// border-radius: 3px;
				font-size: 1.2em;
				font-weight: 400;
				transition: 0.2s ease;
				cursor: pointer;
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
				&:hover, &:active {
					background-color: #803D9D;
					box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
				}
			}
		}
	}

}

.section-artwork {
	margin-top: 30px;
	&__file {
		position: relative;
	}
	&__file-name {
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 8px 0 10px;
	}
}
