$sidebar-width: 500px;

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.app-panel {
	position: relative;
	animation: fadeIn 0.3s ease forwards;
	&.transitioning {
		animation-delay: 4s;
		animation: fadeOut 1s ease forwards;
	}
}

$header-height: 60px;

.inner-container {
	padding-left: 15px;
	padding-right: 15px;
}
.app-container {
	display: flex;
	justify-content: space-between;
	.main-header {
		height: $header-height;
	}
	.app-panel, .panel-inner {
		height: 100vh;
	}
	.panel-inner {
	}
	.sidebar {
		width: 135px;
		position: fixed;
		height: 100%;
		top: 0;
		// top: $header-height;
		z-index: 90;
	}
	.main-panel-outer {
		width: calc(100% - 135px);
		margin-left: 135px;
		padding-top: 60px;
		transition: width 0.3s ease;
		color: $text-dark;
	}
	&.colours, &.artwork, &.submit {
		.main-panel-inner {
			width: calc(100% - #{$sidebar-width});
		}
	}
	&.shirt-selected.shirts {
		.main-panel-inner {
		}
	}
	.preview-pane {
		width: $sidebar-width;
		position: fixed;
		right: 0;
		transition: transform 0 cubic-bezier(0.4, 0, 1, 1);
		transition-delay: 2s;
		padding-top: 60px;
	}
	&.shirt-selected {
		&.shirts {
			.main-panel-inner {
			}
		}
	}
	&.sidebar-closed {
		&.shirts {
			.main-panel-inner {
				// width: 100%;
			}
		}
		.main-panel-outer {
			// width: calc(100% - 135px);
		}
		.preview-pane {
			transform: translateX(100%);
			transition: none;
		}
		&.submit {
			.preview-pane {
				transform: translateX(0);
			}
		}
	}
	&.transition {
		ul.shirt-grid-list li {
			opacity: 0;
			transform: scale(1.1);
		}
	}
}







@media(max-width: 600px) {
	.app-container .main-panel-outer {
		.main-panel-inner {
			padding: 5px;
		}
	}
}













