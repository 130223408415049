.button {
	cursor: pointer;
	font-size: 1.1em;
	display: inline-block;
	padding: 9px 30px 7px;
	text-align: center;
	text-transform: uppercase;
	transition: 0.2s ease;
	background-color: $text-light-grey;
	color: $text-black;
	&:hover, &:active {
		opacity: 0.9;
	}
	&--next-panel {
		margin: 0 auto;
		width: 100%;
	}
	&--purple {
		background-color: $flat-purple;
		color: #fff;
		&:hover {
			background-color: lighten($flat-purple, 8%);
		}
	}
	&--purple {
		background-color: $flat-blue;
		color: #fff;
		&:hover {
			background-color: lighten($flat-blue, 8%);
		}
	}
}