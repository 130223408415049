.submit-form-control-row {

}
.app-form-control {
	&.floating-label {
		position: relative;
		input, textarea {
			&:focus + label,
			&.inputActive + label {
				font-size: 0.8em;
				top: -13px;
				color: #7d7d7d;
			}
			&:focus {
				~ .input-border-bottom {
					right: 0;
				}
				~ label {
					color: $app-purple;
				}
			}
		}
		&.inputActive {
			label {
				font-size: 0.8em;
				top: -13px;
				color: $app-purple;
			}
			.input-border-bottom {
				// right: 0;
			}
		}
		.input-border-bottom {
			position: absolute;
			height: 2px;
			background-color: $app-purple;
			display: block;
			bottom: 0;
			left: 0;
			right: 100%;
			content: "";
			transition: all 0.2s ease;
		}
		label {
			display: block;
			position: absolute;
			top: 0;
			font-size: 1.4em;
			transition: all 0.2s;
			cursor: text;
			color: #4e4e4e;
		}
	}
}
.app-form-control {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
	::-webkit-input-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder,
	:-moz-placeholder { /* Firefox 18- */
		color: #fff;
		font-weight: 100;
		font-weight: 100;
		font-family: inherit;
	}
	.submit-row {
		padding: 10px 0;
		text-align: center;
	}
	button.form-button {
		border: none;
		background-color: transparent;
		text-transform: none;
		padding: 5px 11px 4px;
		margin-left: 8px;
		text-transform: uppercase;
		line-height: 1;
		text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.18);
		font-weight: 100;
		&.active {
			background-color: $green;
			color: #fff;
			box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.24);
		}
		&.danger {
			background-color: $red;
			color: #fff;
			box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.24);
		}
	}
}
input[type="text"],
input[type="email"],
.input--number,
textarea {
	display: block;
	width: 100%;
	border: none;
	border-bottom: solid thin $text-primary;
	padding: 5px 0;
	background-color: transparent;
	outline: none;
	font-size: 1.2em;
	color: #191919;
	font-family: inherit;
	resize: none;
	&:focus {
		outline: none;
	}
}
input[type="text"] {
	
}
input[type="email"] {

}
textarea {

}
input[type="submit"] {
	background-image: none;
	background-color: #CCCCCC;
	border: solid thin #A5A5A5;
	text-transform: uppercase;
	line-height: 1;
	padding: 8px 15px;
	cursor: pointer;
}

.order-note-title {
	text-align: center;
}

.container-narrow {
	max-width: 600px;
	margin: 0 auto;
}
.order-note {
	h4 {
		margin: 0 0 30px 0;
		text-align: center;
	}
}
.order-notes-message {
	input[type="text"] {
	}
	textarea {
		height: 120px;
	}
	.order-note-title {
		text-align: center;
		margin: 120px auto 80px;
		max-width: 350px;
		label {
			display: block;
			text-align: center;
		}
	}
	.app-form-control-row {
		display: flex;
		justify-content: space-between;
		padding: 8px 0;
	}
	.submit-form-control-half {
		width: calc(50% - 20px);
	}
	.submit-row {
		text-align: center;
		padding: 20px 0;
		margin: 0 0 30px 0;
	}
	.message-row {

	}
}