@keyframes hoverAddon {
	0% {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@keyframes focusZoom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes afterZoom {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	100% {
		height: 200%;
		width: 200%;
		opacity: 0;
	}
}

.panel-artwork-addons,
.app-panel-colours {
	
	.section-artwork-addons {
		.app-help-text {
			margin: 20px auto 30px;
			text-align: center;
			max-width: 400px;
			font-size: 0.9em;	
		}
		&.section-artwork, &.section-colour-count {
			margin: 0 auto;
		}
		.section-colour-count-inner {
			margin: 60px 0 20px 0;
		}
		h3 {
			font-weight: 300;
			text-align: center;
			font-size: 1.5em;
		}
	}
	.artwork-columns {
		display: flex;
		justify-content: space-between;
	}
	.artwork-column {
		width: calc(50% - 15px);
		.image-info-outer {
			text-align: center;
			p {
				margin: 3px 0;
			}
			.artwork-remove {
				margin-top: 3px;
			}
		}
	}
	.dropzone {
		display: none;
	}

	
	/* colour count */
	.section-colour-count {
		.colour-count-columns {
			display: flex;
			justify-content: space-between;
			.colour-count-column {
				width: calc(50% - 15px);
				text-align: center;
				align-items: center;
				display: flex;
				justify-content: flex-start;
				label, input {
					max-width: 80px;
					margin: 0 auto;
					text-align: center;
					background-color: transparent;
				}
				label {
					max-width: initial;
					font-size: 0.9em;
				}
				.colour-count-input-outer {
					margin-top: 20px;
					max-width: 120px;
					margin: 0;
					font-size: 1.4em;
					input {
						font-size: 1.5em;
						width: 50px;
					}
				}
				label {
					font-weight: 500;
					color: #313131;
					font-size: 0.9em;
					margin: 0;
				}
			}
		}

		.colour-count-input-outer {
			.Select-control {
				border-radius: 0;
				border: none;
				cursor: pointer;
				background-color: #e6e6e6;
				background-color: transparent;
			}
			/* value in normal state */
			.has-value.Select--single > .Select-control .Select-value .Select-value-label,
			.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
				font-weight: 500;
				font-size: 1.2em;
			}
			
			/* hover in dropdown options */
			.Select-menu-outer {
				background-color: transparent;
				font-size: 0.8em;
			}

			/* dropdown options */
			.Select-option {
				background-color: #e6e6e6;
				background-color: $light-bg;
				border: none;
				font-weight: 500;
				font-family: inherit;
				&:hover {
					background-color: darken(#e6e6e6, 5%);
				}
			}
			.Select-clear {
				display: none;
			}
		}
	}

}


