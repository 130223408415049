/* structure */
$sidebar_width: 250px;

$text-black: #404040;
$text-light-grey: #7B7B7B;

$light-white-bg: #f5f5f5;
$light-white: #f5f5f5;
$light-border-color: #eee;
$light-label-color: #989898;


$green: #27ae60;
$purple: #8e44ad;
$blue: #2980b9;
$red: #e74c3c;
$blue-border: #226DA0;






/*
Light UI Colours
 */

/* content colours */
/* $app-primary: #F44336; */
/* $app-secondary: #9C27B0; */
$app-primary: #F44336;
$app-secondary: #F44336;
$text-dark: #212121;
$text-primary: #424242;
$text-secondary: #424242;
$text-light: #9E9E9E;

$label-disabled: #717171;

/* background colours */
$black: #2A2A2A;	/* grey */
$header-bg: #1c2529;
$sidebar-bg: #263238;
$dark-bg: #263238;	/* blue-grey */
$light-bg: #ebeff1;	/* grey */
$light-border: #E0E0E0;	/* grey */
$app-purple: #6A1B9A; /* purple */
$app-green: #16a085; /* green */

/* cards */
$box-shadow: -1px 1px 18px 2px rgba(0, 0, 0, 0.26);
$card-border: solid thin #E0E0E0;	/* grey */


/**
 * Flat
 */
$flat-blue: #1565c0;
$flat-red: #c0392b;
$flat-red-light: #e74c3c;
$flat-purple: #8e44ad;
$flat-purple-light: #9b59b6;









