/* filter */
.shirt-filter-outer {
	.filter-list-outer {
		margin: 0 0 30px 0;
	}
	h4 {
		text-align: center;
	}
	ul {
		list-style-type: none;
		width: 100%;
		text-align: left;
		margin: 0;
		padding: 0;
		max-width: 100%;
		margin: 0 auto;
		li {
			text-align: center;
			padding: 0 10px;
			display: inline-block;
			margin-bottom: 5px;
		}
		li.current a {
			background-color: $flat-red-light;
			color: #fff;
			box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.4);
			&:focus {
				animation: focusFade 0.1s ease;
			}
		}
		a {
			&:link, &:visited, &:active {
				transition: all 0.25s ease;
				color: #717171;
				padding: 10px 17px 8px;
				display: block;
				position: relative;
				font-size: 0.95em;
				background: #dad9de;
				text-transform: uppercase;
				font-weight: 500;
			}
			&:hover {
				background-color: #CAC7D0;
				color: $text-primary;
			}
			&:focus {
				animation: focusZoom 0.1s ease;
				opacity: 1;
			}
			span {
				display: block;
				border-radius: 50%;
				border: solid thin;
				height: 19px;
				width: 19px;
				position: absolute;
				left: 0;
				top: 6px;
				&:before {
					content: "";
					height: 13px;
					width: 13px;
					position: absolute;
					border-radius: 50%;
					left: 2px;
					top: 2px;
				}
			}
		}
	}
}
