.section-customize {
	display: block;
	&--shirt-info {
		position: relative;
		z-index: 20;
	}
	&--colour-qty-print-count {
		width: 100%;
		color: $text-black;
		box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.24);
		background-color: #fff;
		overflow: visible;
		padding: 20px 30px;
		margin-top: 30px;
	}
}


.section-row {
	&--colour-qty-form {
		margin-bottom: 17px;
	}
}



.shirt-colour-pricing {
	max-width: 350px;
	margin: 7px auto 25px;
}
.shirt-info {
	&__data {
		height: 27px;
		margin-top: 2px;
		font-weight: 400;
		font-size: 1.2em;
	}
	&__brand {
		text-transform: uppercase;
		color: $text-light;
		font-size: 0.95em;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		padding: 0 2px;
	}
	&__pricing-total {
		color: $flat-red;
	}
	&__pricing-total-label {
		color: #7d7d7d;
		font-size: 0.9em;
	}
	&__description {
		margin: 20px 0 40px;
	}
}
